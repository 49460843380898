<template >
    <div>
        <Header/>
        <div id="Servicios">
            <div class="sec-1">
                <div class="text-cont">
                    <h1>Helping you with good ideas.</h1>
                    <h2>
                        Nuestros servicios de branding, desarrollo web y marketing digital nos lleva a dar experiencias integrales donde le damos el máximo potencial a tu marca.
                    </h2>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import Header from '../components/on-site/Header.vue'
import Footer from '../components/on-site/Footer.vue'
export default {
    components:{
        Header,Footer
    }
}
</script>
<style scoped>
    
</style>